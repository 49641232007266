import styled from "styled-components";

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  grid-gap: 10px;
`;

export const DragButton = styled.div`
  display: grid;
  justify-items: center;
  gap: 15px;
  padding: 18px 40px;
  border: 2px dashed #bfcfe0;
  border-radius: 6px;
  color: rgba(31, 41, 57, 0.7);
  font-size: 13px;
  font-weight: 400;
  background-color: ${(props) =>
    props.isDragging ? `rgba(77, 124, 255, 0.15)` : `#FCFDFE`};
  text-align: center;
  cursor: pointer;
`;

export const UploadImageSection = styled.div`
  display: grid;
  grid-template-columns: 130px auto;
  justify-content: start;
  grid-gap: 15px;
  padding: 20px 0;
`;
export const UploadImageButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-gap: 15px;
`;
export const NestedFormContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  grid-gap: 15px;
  padding-left: 20px;
  border-left: 1px solid rgb(240, 244, 248);
`;
export const InputRow = styled.div`
  display: grid;
  grid-template-columns: minmax(320px, 380px) auto;
  justify-content: start;
  align-content: start;
  grid-gap: 20px;
  @media (max-width: 540px) {
    grid-template-columns: 100%;
   // padding: 10px 20px;
    
  }
`;
export const AlignSelectContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  border: 1px solid #bfcfe0;
  border-radius: 6px;
  background-color: #fcfdfe;
  justify-content: start;
  margin-right: auto;
  grid-gap: 5px;
`;
export const AlignSectionItem = styled.div`
  display: grid;
  align-items: start;
  padding: 10px 12px;
  border-radius: 6px;
  cursor: pointer;
  border: ${(props) =>
    props.selected ? `1px solid #3A6B60` : `1px solid white`};
  background-color: ${(props) => (props.selected ? `#3A6B60` : `#FFF`)};
  &:hover {
    border-color: rgba(77, 124, 255, 1);
  }
`;
export const TimeInputContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export const TextElementSection = styled.div`
  display: grid;
  gap: 10px;
`;
export const FormText = styled.p`
  margin: ${(props) => (props.margin ? props.margin : `10px 0 0 0`)};
  color: rgba(31, 41, 57, 0.7);
  font-size: 13px;
  font-weight: 500;
`;
export const ColorViewer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 27px 80px;
  grid-gap: 13px;
  padding: 8px 15px;
  border-radius: 6px;
  border: 1px solid #bfcfe0;
  background-color: #fcfdfe;
  color: rgba(31, 41, 57, 0.7);
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-color: rgba(77, 124, 255, 1);
  }
`;
export const ColorViewerRound = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 2px solid #bfcfe0;
  background-color: ${(props) => props.color && props.color};
`;
export const ColorPalllateContainer = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  z-index: 10;
`;

export const CrossIcon = styled.div`
  position: absolute;
  top: -25px;
  right: -40px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #bfcfe0;
`;
export const PositionButton = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 7px;
  align-content: center;
  /* align-items: center; */
  justify-content: center;
  width: 140px;
  padding: 13px 20px;
  background-color: ${(props) => (props.selected ? `#3A6B60` : `#FCFDFE`)};
  color: ${(props) => (props.selected ? `#FFF` : `#1F2939`)};
  text-align: center;
  font-size: 14px;
  border-radius: 6px;
  border: ${(props) =>
    props.selected ? `1px solid #3A6B60` : `1px solid #BFCFE0`};
  cursor: pointer;
  @media (max-width: 767px) {
    padding: 12px 25px;
  }
`;
export const InputIcon = styled.img`
  position: absolute;
  top: 22px;
  transform: translate(0, -50%);
  left: 10px;
  width: 25px;
`;
export const SocialURLContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: minmax(320px, 380px);
  justify-content: start;
`;
export const FontPickerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  border-radius: 6px;
  border: ${(props) =>
    props.expanded ? `1px solid #3A6B60` : `1px solid #bfcfe0`};
  background-color: #fcfdfe;
  color: #1f2939;
  font-size: 13px;
  min-height: 45px;
  &:hover {
    border-color: #3A6B60;
  }
`;
export const OptionRowButton = styled.button`
  background-color: ${(props) => props.selected && `#3A6B6080 !important`};
  &:hover {
    background-color: ${(props) =>
      !props.selected && `rgba(77, 124, 255, 0.2) !important`};
  }
`;
export const NoDiscountBox = styled.div`
  padding: 20px 30px;
  grid-column: 1 / span 2;
  background-color: #F1F5FF;
  border: 2px dashed #3A6B60;
  border-radius: 6px;
  text-align: center;
  font-size: 14px;
  color: rgba(31, 41, 57, 0.7);
`
export const CouponsTable = styled.div`
  grid-column: 1 / span 2;
  border: 1px solid #BFCFE0;
  border-radius: 6px;
  background-color: #FCFDFE;
  display: grid;
  grid-template-columns: 30px repeat(3, 1fr);      
`
export const CouponsTableCell = styled.div`
  padding: 10px 20px;
  color: rgba(31, 41, 57, 0.7);
  font-weight: ${({header}) => header && `500`};
  border-bottom: ${({header}) => header && `1px solid #BFCFE0`};
  font-size: 13px;
`
export const CouponRadioButton = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  outline: 1px solid #BFCFE0;
  border: 3px solid #FFF;
  background-color: ${({selected}) => selected ? `#3A6B60`:`#FFF`};
  margin: auto 5px auto 10px;
`
