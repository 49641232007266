import React, { useState } from "react";
import { connect } from "react-redux";
import {
  updateRouteState,
  updateTemplateType,
  updateTemplateTypeNumber,
} from "../actions/actionRoute";
import { BASE_DETAIL_VIEW } from "../constants/componentName";
import {
  FREE,
  SUBSCRIPTION
} from "../constants/templateConstants";

import {
  BaseContainer,
  CrossIcon,
  ListContainer,
  PremiumBar,
  PremiumButton,
  PreviewContainer,
  PreviewPopup,
  TemplateCardHoverContainer,
  TemplateImageCard,
  TemplateTypeCapsule,
  TemplateTypeContainer,
  WhiteCross,
} from "../styledComponent/baseListVIew";

import allImages from "./allImages";

import axios from "axios";
import crossWhite from "../assets/icons/cross-white.svg";
import crossBlack from "../assets/icons/cross_black.svg";
import crownWhite from "../assets/icons/crown-white.svg";
import premiumIcon from "../assets/icons/crown.svg";
import { PREMIUM_PLAN } from "../constants";
import { CommonText, IconTextButtonGradient } from "../styledComponent/common";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";

const BaseListView = (props) => {
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPremiumBar, setShowPremiumBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const changeRoute = (templateType, templateTypeNumber, isPremium) => {
    // if (isPremium) {
    //   setShowPremiumBar(true);
    // } else {
      props.updateTemplateType({ data: templateType });
      props.updateTemplateTypeNumber({
        data: templateTypeNumber,
      });
      props.handleUpdateRouteState({
        data: BASE_DETAIL_VIEW,
      });
    // }
  };

  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  const selectPreview = (value, image) => {
    setIsPreviewActive(value);
    if (value) {
      setPreviewImage(image);
    }
  };

  const changeTemplateType = (value) => {
    props.updateTemplateType({
      data: value,
    });
  };

    const pricingPlanCall = async () => {
      setIsLoading(true)
      try {
        const URL =
          `${process.env.REACT_APP_API_URL}/api/yearly_starter/?shop=${props.shop}`.toString();
        
        const response = await axios.get(URL);
        if (response && response.status && response.status === 200) {
          window.open(response.data.url, "_top");
        }
      } catch (errors) {
        console.error(errors);
      }
      setIsLoading(true)
    };

  return (
    <BaseContainer>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          {isPreviewActive && (
            <PreviewContainer onClick={() => selectPreview(false)}>
              <PreviewPopup>
                <img
                  src={previewImage}
                  alt="preview"
                  width="100%"
                  onClick={(e) => onPopupClick(e)}
                />
                <CrossIcon>
                  <img src={crossBlack} alt="cross-icon" width="100%" />
                </CrossIcon>
              </PreviewPopup>
            </PreviewContainer>
          )}
          {showPremiumBar && (
            <PreviewContainer
              justifyContent="initial"
              onClick={() => setShowPremiumBar(false)}
            >
              <PremiumBar onClick={(e) => onPopupClick(e)}>
                <CommonText color="white">
                  This template is a premium template. Upgrade to use it.
                </CommonText>
                <IconTextButtonGradient>
                  <img src={crownWhite} alt="crown white" /> <div>UPGRADE</div>
                </IconTextButtonGradient>
                <WhiteCross onClick={() => setShowPremiumBar(false)}>
                  <img src={crossWhite} alt="cross-icon" width="100%" />
                </WhiteCross>
              </PremiumBar>
            </PreviewContainer>
          )}
          <TemplateTypeContainer>
            <TemplateTypeCapsule
              onClick={() => changeTemplateType(null)}
              isActive={props.template_type === null}
            >
              All
            </TemplateTypeCapsule>
            <TemplateTypeCapsule
              onClick={() => changeTemplateType(FREE)}
              isActive={props.template_type === FREE}
            >
              Free
            </TemplateTypeCapsule>
            <TemplateTypeCapsule
              onClick={() => changeTemplateType(SUBSCRIPTION)}
              isActive={props.template_type === SUBSCRIPTION}
            >
              Premium
            </TemplateTypeCapsule>
          </TemplateTypeContainer>
          <ListContainer>
            {allImages
              .filter(
                (filteredItem) =>
                  filteredItem.template_type === props.template_type ||
                  props.template_type === null
              )
              .map((item, index) => (
                <TemplateImageCard key={index}>
                  <img
                    src={item.image}
                    alt={item.template_type_number}
                    width="100%"
                  />
                  <TemplateCardHoverContainer>
                    <TemplateTypeCapsule
                      onClick={() => selectPreview(true, item.image)}
                    >
                      Preview
                    </TemplateTypeCapsule>

                    {item.isPremium && props.plan !== PREMIUM_PLAN ? (
                      <IconTextButtonGradient
                        onClick={() => pricingPlanCall()}
                        radius="26px"
                      >
                        <img src={crownWhite} alt="crown white" />{" "}
                        <div>UPGRADE</div>
                      </IconTextButtonGradient>
                    ) : (
                      <TemplateTypeCapsule
                        isActive={true}
                        onClick={() =>
                          changeRoute(
                            item.template_type,
                            item.template_type_number,
                            item.isPremium
                          )
                        }
                      >
                        Customize
                      </TemplateTypeCapsule>
                    )}
                  </TemplateCardHoverContainer>
                  {item.isPremium && (
                    <PremiumButton>
                      <img src={premiumIcon} alt="crown" />
                    </PremiumButton>
                  )}
                </TemplateImageCard>
              ))}
          </ListContainer>
        </>
      )}
    </BaseContainer>
  );
};

const mapStateToProps = (state) => ({
  route: state.route.link,
  template_type: state.route.template_type,
  template_type_number: state.route.template_type_number,
  plan: state.campaign.currentPlan,
  shop: state.campaign.shop
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateRouteState: (params) => dispatch(updateRouteState(params)),
  updateTemplateType: (params) => dispatch(updateTemplateType(params)),
  updateTemplateTypeNumber: (params) =>
    dispatch(updateTemplateTypeNumber(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseListView);
