import createApp from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";
import axios from "axios";
import { apiKey } from "../constants/shopifyConstants";

const reqData = {
  method: "GET",
  url: "",
  queryParams: {},
  formData: {},
  bodyParams: {},
  pathParams: [],
  data: {},
};

function makeHeaders() {
  const headers = {
    "Content-Type": "application/json",
  };
  return headers;
}

function makeHeaderWithToken(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  return headers;
}

function makeHeaderWithTokenAndFormData(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  return headers;
}

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const setHost = () => {
  let host = "";
  if (typeof window !== "undefined") {
    host = params.host;
  }
  return host;
};

const handleGetSessionToken = async () => {
  const host = setHost();
  const app = createApp({
    apiKey: apiKey,
    host: host,
  });
  const sessionToken = await getSessionToken(app);
  return sessionToken;
};

export function defaultApi(url, method, details) {
  axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  axios.defaults.xsrfCookieName = "csrftoken";
  axios.defaults.withCredentials = true;

  const URL = `${process.env.REACT_APP_API_URL}/api/`.toString();

  const api = axios.create({
    baseURL: URL,
  });

  api.interceptors.request.use(
    async (config) => {
      try {
        const sessionToken = await handleGetSessionToken();
        config.headers["Authorization"] = `Bearer ${sessionToken}`;
        return config;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let requestDetails = { ...reqData };
  requestDetails.url = url;
  requestDetails.method = method;
  requestDetails.data = details;
  return api(requestDetails).then((response) => response);
}
