import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  updateDisplayRules,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import crown from "../../assets/icons/crown.svg";
import crossWhite from "../../assets/icons/cross-white.svg";
import crownWhite from "../../assets/icons/crown-white.svg";
import { PREMIUM_PLAN } from "../../constants";
import { CommonText, IconTextButtonGradient, TextWithIcon } from "../../styledComponent/common";
import {
  ChangableCompContainer,
  Maindiv,
  RoundSelect,
  RoundSelectText
} from "../../styledComponent/step2";
import { PremiumBar, PreviewContainer, WhiteCross } from "../../styledComponent/baseListVIew";
import axios from "axios";

const DownloadType = (props) => {
      const [patform, setPlayform] = useState("");
      const [isPremium, setIsPremium] = useState(false);
      const [showPremiumBar, setShowPremiumBar] = useState(false);

      useEffect(() => {
        // console.log("Platform ", props.displayRules.campaign_platforms);
        setPlayform(props.displayRules.campaign_platforms);

        if (props.plan === PREMIUM_PLAN) {
          setIsPremium(true);
        }
      }, []);

      const onChangeDownloadType = (value) => {
        props.updateDisplayRules({ data: { download_type: value } });
      }
      const pricingPlanCall = async (type) => {
        try {
          const URL =
            `${process.env.REACT_APP_API_URL}/api/${type}/?shop=${props.shop}`.toString();
          const response = await axios.get(URL);
          if (response && response.status && response.status === 200) {
            window.open(response.data.url, "_top");
          }
        } catch (errors) {
          console.error(errors);
        }
      };

      const onPopupClick = (e) => {
        e.stopPropagation();
        pricingPlanCall("yearly_starter");
    };

  return (
    <ChangableCompContainer>
      {showPremiumBar && (
        <PreviewContainer
          justifyContent="initial"
          onClick={() => setShowPremiumBar(false)}
        >
          <PremiumBar onClick={(e) => onPopupClick(e)}>
            <CommonText color="white">
              This template is a premium template. Upgrade to use it.
            </CommonText>
            <IconTextButtonGradient>
              <img src={crownWhite} alt="crown white" /> <div>UPGRADE</div>
            </IconTextButtonGradient>
            <WhiteCross onClick={() => setShowPremiumBar(false)}>
              <img src={crossWhite} alt="cross-icon" width="100%" />
            </WhiteCross>
          </PremiumBar>
        </PreviewContainer>
      )}
      <CommonText>Select a download type for your campaign</CommonText>
      <Maindiv
        colum={"40% 60%"}
        gap={"4%"}
        responsive={"100%"}
        responsiveValue={"670px"}
      >
        <RoundSelectText onClick={() => onChangeDownloadType("DIRECT")}>
          <RoundSelect selected={props.displayRules.download_type === "DIRECT"}>
            <div></div>
          </RoundSelect>
          <CommonText>Direct Download</CommonText>
        </RoundSelectText>
        <RoundSelectText
          onClick={() => {
            isPremium ? onChangeDownloadType("EMAIL") : setShowPremiumBar(true);
          }}
        >
          <RoundSelect selected={props.displayRules.download_type === "EMAIL"}>
            <div></div>
          </RoundSelect>
          <TextWithIcon>
            <CommonText>Send Download Link Via Email</CommonText>
            <img
              style={{ width: "20px", height: "20px" }}
              src={crown}
              alt="crown white"
            />
          </TextWithIcon>
        </RoundSelectText>
      </Maindiv>
    </ChangableCompContainer>
  );
};

const mapStateToProps = (state) => ({
  saveErrors: state.template.saveErrors,
  displayRules: state.template.displayRules,
  plan: state.campaign.currentPlan,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DownloadType);
