import crossBlack from "../assets/icons/cross_black.svg";
import { CrossIcon, PreviewContainer } from "../styledComponent/baseListVIew";
import {
  PreviewPopup,
} from "../styledComponent/campaignList";
import {
  CommonColorButton,
  CommonTitle
} from "../styledComponent/common";
import { InputFieldContainer, IntegrationFieldWrapper, IntegrationInput, ModalButtonSection } from "../styledComponent/integration";
import { FormText } from "../styledComponent/step1";

const IntegrationModal = ({ text, configureFields, handleOnChange, buttonText, toggle, onAccept, deleteIntegration, isLoading }) => {

  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  // console.log("configureFields", configureFields);

  return (
    <PreviewContainer onClick={toggle}>
      <PreviewPopup maxWidth="550px">
        <div
          style={{ padding: `35px 30px 15px` }}
          onClick={(e) => onPopupClick(e)}
        >
          <CommonTitle fontSize="18px" name="true">
            {text}
          </CommonTitle>
          <IntegrationFieldWrapper>
            {configureFields.fields.map((field, index) => (
              <InputFieldContainer key={index}>
                <FormText name={field.name}>{field.label}</FormText>
                <IntegrationInput
                  defaultValue={field.value}
                  error={field.error}
                  name={field.name}
                  onChange={(e) => handleOnChange(field.name, e.target.value)}
                  type={field.type}
                  placeholder={field.placeholder}
                />
              </InputFieldContainer>
            ))}

            {/* <CommonText margin="30px 0 0">{description}</CommonText> */}
          </IntegrationFieldWrapper>
          <ModalButtonSection>
            {buttonText === "Update" && (
              <CommonColorButton
               bgColor="#fc5768" onClick={deleteIntegration} disabled={isLoading.delete}>
                { isLoading.delete ? "Processing" : "Delete" }
              </CommonColorButton>
            )}
            <CommonColorButton bgColor="#3A6B60" onClick={onAccept} disabled={isLoading.update}>
              { isLoading.update ? "Processing" : buttonText }
            </CommonColorButton>
          </ModalButtonSection>
        </div>
        <CrossIcon>
          <img src={crossBlack} alt="cross-icon" width="100%" />
        </CrossIcon>
      </PreviewPopup>
    </PreviewContainer>
  );
};

export default IntegrationModal;
