import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  padding: ${({ padding }) => padding || "20vh 0"};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderDiv = styled.div`
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
`;

const Spinner = styled.div`
  position: absolute;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: 4px solid #3A6B60;
  border-top-color: transparent;
  border-radius: 50%;
  top: 50px;
  left: 50px;
  animation: ${spin} 1s ease-in-out infinite;
  box-sizing: content-box;
  opacity: 50%;
`;

const ComponentCircleLoader = (props) => {
  return (
    <LoaderContainer padding={props.padding}>
      <LoaderDiv>
        <SpinnerContainer>
          <Spinner size={props.size || 25} />
        </SpinnerContainer>
      </LoaderDiv>
    </LoaderContainer>
  );
};

export default ComponentCircleLoader;
