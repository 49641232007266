//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../utils/axiosApi";

//Constants
import {
  CD_DATE_FILTER_DATA,
  CD_DATE_FILTER_DATA_FAILURE,
  CD_DATE_FILTER_DATA_SUCCESS,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_FAILURE,
  CREATE_CAMPAIGN_SUCCESS,
  DASHBOARD_FILTER,
  DASHBOARD_FILTER_FAILURE,
  DASHBOARD_FILTER_SUCCESS,
  DATE_FILTER_DATA,
  DATE_FILTER_DATA_FAILURE,
  DATE_FILTER_DATA_SUCCESS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_LEAD,
  DELETE_LEAD_FAILURE,
  DELETE_LEAD_SUCCESS,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_LIST_FAILURE,
  GET_CAMPAIGN_LIST_SUCCESS,
  GET_CAMPAIGN_VIA_ID,
  GET_CAMPAIGN_VIA_ID_FAILURE,
  GET_CAMPAIGN_VIA_ID_SUCCESS,
  GET_LEADS_LIST,
  GET_LEADS_LIST_FAILURE,
  GET_LEADS_LIST_SUCCESS,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_SUCCESS,
} from "../constants";

export function* getCampaignList({ payload }) {
  try {
    let url = ``;
    if (payload === undefined) {
      url = `campaign/`;
    } else {
      url = `campaign/?page=${payload}`;
    }
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_CAMPAIGN_LIST_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_CAMPAIGN_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getLeadsList({ payload }) {
  try {
    let url = ``;
    if (payload === undefined) {
      url = `leads/`;
    } else {
      url = `leads/?page=${payload}`;
    }
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_LEADS_LIST_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_LEADS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* createCampaign({ payload }) {
  try {
    const { template_type, type, ...details } = payload;
    const url = `${template_type}/`;
    const { campaign_name, font, form, embd_settings, template } =
      details;
    const { uploaded_file, ...rest } = embd_settings;
    const formData = new FormData();
    formData.append("campaign_name", campaign_name);
    formData.append("font", JSON.stringify(font));
    formData.append("form", JSON.stringify(form));
    formData.append("embd_settings", JSON.stringify(rest));
    formData.append("template", JSON.stringify(template));
    formData.append("type", type);
    if (uploaded_file) {
      formData.append("file", uploaded_file);
    }

    const method = "POST";
    const response = yield defaultApi(url, method, formData);

    yield put({
      type: CREATE_CAMPAIGN_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: CREATE_CAMPAIGN_FAILURE,
      payload: err,
    });
  }
}

export function* getCampaignViaId({ payload }) {
  if (!payload) return;
  try {
    const { template_type, campaign_id } = payload;
    const url = `${template_type}/${campaign_id}/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_CAMPAIGN_VIA_ID_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_CAMPAIGN_VIA_ID_FAILURE,
      payload: err,
    });
  }
}

// export function* updateCampaign({ payload }) {
//   try {
//     const { template_type, campaign_id, ...details } = payload;
//     const url = `${template_type}/${campaign_id}/`;
//     console.log("Printing details", details)
//     const response = yield defaultApi(url, "PATCH", details);
//     yield put({
//       type: UPDATE_CAMPAIGN_SUCCESS,
//       payload: { data: response },
//     });
//   } catch (err) {
//     yield put({
//       type: UPDATE_CAMPAIGN_FAILURE,
//       payload: err,
//     });
//   }
// }

export function* updateCampaign({ payload }) {
  try {
    const { template_type, campaign_id, ...details } = payload;
    const url = `${template_type}/${campaign_id}/`;
    console.log("Printing details", details);
    const onlyupdate = Object.keys(details).length === 1;

    const formData = new FormData();

    if (!onlyupdate) {
      const {
        campaign_name,
        font,
        form,
        embd_settings = {},
        status,
        template,
      } = details;
      const { uploaded_file = null, ...rest } = embd_settings;
      formData.append("campaign_name", campaign_name);
      formData.append("font", JSON.stringify(font));
      formData.append("form", JSON.stringify(form));
      formData.append("embd_settings", JSON.stringify(rest));
      formData.append("status", status);
      formData.append("template", JSON.stringify(template));

      if (uploaded_file || uploaded_file !== null) {
        formData.append("file", uploaded_file);
      }
    }

    const response = yield defaultApi(
      url,
      "PATCH",
      onlyupdate ? details : formData
    );
    yield put({
      type: UPDATE_CAMPAIGN_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    console.log("Printing eroor in update campaign", err);
    yield put({
      type: UPDATE_CAMPAIGN_FAILURE,
      payload: err,
    });
  }
}



export function* deleteCampaign({ payload }) {
  try {
    const { template_type, campaign_id } = payload;
    const url = `${template_type}/${campaign_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_CAMPAIGN_SUCCESS,
      payload: { data: response, notify: "DELETE" },
    });
  } catch (err) {
    yield put({
      type: DELETE_CAMPAIGN_FAILURE,
      payload: err,
    });
  }
}

export function* deleteLead({ payload }) {
  try {
    const { lead_id } = payload;
    const url = `leads/${lead_id}/`;
    const method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_LEAD_SUCCESS,
      payload: { data: response, notify: "DELETE" },
    });
  } catch (err) {
    yield put({
      type: DELETE_LEAD_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardFilter() {
  try {
    const url = `dashboard_graph`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: DASHBOARD_FILTER_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: DASHBOARD_FILTER_FAILURE,
      payload: err,
    });
  }
}

export function* getFilteredData({ payload }) {
  try {
    let url = ``;
    if (payload === undefined) {
      url = `filtered_data/`;
    } else {
      url = `filtered_data/?fdate=${payload.fromDate}&tdate=${payload.toDate}`;
    }
    const response = yield defaultApi(url, "GET");
    yield put({
      type: DATE_FILTER_DATA_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: DATE_FILTER_DATA_FAILURE,
      payload: err,
    });
  }
}
export function* getCDFilteredData({ payload }) {
  try {
    let url = ``;
    if (payload === undefined) {
      url = `campaign_stat/`;
    } else {
      url = `campaign_stat/${payload.uid}/?fdate=${payload.fromDate}&tdate=${payload.toDate}&type=${payload.type}`;
    }
    const response = yield defaultApi(url, "GET");
    yield put({
      type: CD_DATE_FILTER_DATA_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: CD_DATE_FILTER_DATA_FAILURE,
      payload: err,
    });
  }
}
export default function* root() {
  yield all([
    //Get campaign list
    takeLatest(GET_CAMPAIGN_LIST, getCampaignList),
    //Get leads list
    takeLatest(GET_LEADS_LIST, getLeadsList),
    //Create campaign
    takeLatest(CREATE_CAMPAIGN, createCampaign),
    //get campaign via id
    takeLatest(GET_CAMPAIGN_VIA_ID, getCampaignViaId),
    //update camapaign
    takeLatest(UPDATE_CAMPAIGN, updateCampaign),
    //delete campaign
    takeLatest(DELETE_CAMPAIGN, deleteCampaign),
    //delete lead
    takeLatest(DELETE_LEAD, deleteLead),
    //dashboard filter
    takeLatest(DASHBOARD_FILTER, dashboardFilter),
    //dashboard date wise filter
    takeLatest(DATE_FILTER_DATA, getFilteredData),

    takeLatest(CD_DATE_FILTER_DATA, getCDFilteredData),
  ]);
}
