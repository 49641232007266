export const SUBSCRIPTION = "subscription";
export const FREE = "free";
export const WELCOME_MESSAGES = "welcomemessages";
export const PROMO_CODES = "promocodes";
export const SMART_DISCOUNTS = "smartdiscounts";
export const SOCIAL_DISCOUNTS = "socialdiscounts";
export const SOCIAL_BOOSTS = "socialboosts";

//subscribe templates
export const SUBSCRIPTION_1 = "subscription_1";
export const SUBSCRIPTION_2 = "subscription_2";
export const SUBSCRIPTION_3 = "subscription_3";
export const SUBSCRIPTION_4 = "subscription_4";
export const SUBSCRIPTION_5 = "subscription_5";

//welcome message templates
export const WELCOME_MESSAGES_1 = "welcomemessages_1";
export const WELCOME_MESSAGES_2 = "welcomemessages_2";
export const WELCOME_MESSAGES_3 = "welcomemessages_3";
export const WELCOME_MESSAGES_4 = "welcomemessages_4";
export const WELCOME_MESSAGES_5 = "welcomemessages_5";

//promo codes templates
export const PROMO_CODES_1 = "promocodes_1";
export const PROMO_CODES_2 = "promocodes_2";
export const PROMO_CODES_3 = "promocodes_3";
export const PROMO_CODES_4 = "promocodes_4";
export const PROMO_CODES_5 = "promocodes_5";

//smart discount templates
export const SMART_DISCOUNTS_1 = "smartdiscounts_1";
export const SMART_DISCOUNTS_2 = "smartdiscounts_2";
export const SMART_DISCOUNTS_3 = "smartdiscounts_3";
export const SMART_DISCOUNTS_4 = "smartdiscounts_4";
export const SMART_DISCOUNTS_5 = "smartdiscounts_5";

//social discount templates
export const SOCIAL_DISCOUNTS_1 = "socialdiscounts_1";
export const SOCIAL_DISCOUNTS_2 = "socialdiscounts_2";
export const SOCIAL_DISCOUNTS_3 = "socialdiscounts_3";
export const SOCIAL_DISCOUNTS_4 = "socialdiscounts_4";
export const SOCIAL_DISCOUNTS_5 = "socialdiscounts_5";

//social boost templates
export const SOCIAL_BOOSTS_1 = "socialboosts_1";
export const SOCIAL_BOOSTS_2 = "socialboosts_2";
export const SOCIAL_BOOSTS_3 = "socialboosts_3";
export const SOCIAL_BOOSTS_4 = "socialboosts_4";
export const SOCIAL_BOOSTS_5 = "socialboosts_5";
