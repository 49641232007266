import styled from "styled-components";


export const IntegrationBody = styled.div`
background-color: #f0f4f8;
height: 100vh;
`;
export const IntegrationContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;
    margin-left: 15px;
`;

export const Integration = styled.div`
  background-color: ${props => props.isActive ? '#4caf50' : '#fff'};
  border-radius: 10px;
  padding: 25px;
  width: 300px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${props => props.isActive ? '0 0 10px rgba(76, 175, 80, 0.5)' : '0 0 10px rgba(0, 0, 0, 0.1)'};
  position: relative;
`;

export const IntegrationImage = styled.img`
  height: auto;
  width: 100px;
`;

export const IntegrationTitle = styled.h2`
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
`;

export const IntegrationDescription = styled.p`
  margin: ${(props) => (props.margin ? props.margin : `auto 0`)};
  color: ${(props) => (props.name ? `#2F394E` : `rgba(47, 57, 78, 0.7)`)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : `14px`)};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
    text-align: center;
`;

export const IntegrationButton = styled.button`
  background-color: ${(props) => (props.isConfigured ? "#3A6B60" : "#2f394e")};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  letter-spacing: 1px;
  font-weight: 500;

  &:hover {
    background-color: ${(props) =>
      props.isConfigured ? "#3A6B60" : "#3f4a6e"};
  }
`;

export const IntegrationInput = styled.input`
  border-radius: 6px;
  min-width: 32px;
  border: ${(props) =>
    props.error ? `1px solid #fc5768` : `1px solid #BFCFE0`};
  background-color: ${(props) =>
    props.error ? `rgba(252, 87, 104, 0.05)` : `#FCFDFE`};
  color: #1f2939;
  font-size: 13px;
  padding: 5px 10px;
  min-height: 43px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    outline-color: #c2cce1;
  }
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px
`;

export const IntegrationFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 20px;
`;

export const ModalButtonSection = styled.div`
gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
  padding: 15px 0px 0;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : `end`};
  margin-top: ${(props) => (!props.details ? `25px` : `35px`)};
`;

export const EditIcon = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const ActiveSign = styled.img`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 15px;
  left: 15px;
`;

export const IntegrationPremiumContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: grid;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : `center`};
  align-content: ${(props) =>
    props.alignContent ? props.alignContent : `center`};
`;

export const IntegrationPremiumBar = styled.div`
  text-align: center;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  gap: 20px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  background-color: #1f2939;
`;