import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  updateDisplayRules,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import cross from "../../assets/icons/cross_black.svg";
import crownWhite from "../../assets/icons/crown-white.svg";
import crossWhite from "../../assets/icons/cross-white.svg";
import crown from "../../assets/icons/crown.svg";
import { PREMIUM_PLAN } from "../../constants";
import {
  CommonText,
  CrossContainer,
  FileInput,
  FileLabel,
  FormAlert,
  IconTextButtonGradient,
  InputField,
  TextWithIcon,
  TextWithIconAndBorder
} from "../../styledComponent/common";
import { InputRow } from "../../styledComponent/step1";
import {
  ChangableCompContainer,
  Maindiv,
  RoundSelect,
  RoundSelectText,
} from "../../styledComponent/step2";
import { PremiumBar, PreviewContainer, WhiteCross } from "../../styledComponent/baseListVIew";
import axios from "axios";

const FileToDownload = (props) => {
  const [patform, setPlayform] = useState("");
  const [filename, setFilename] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [showPremiumBar, setShowPremiumBar] = useState(false);

  useEffect(() => {
    // console.log("Platform ", props.displayRules.campaign_platforms);
    setPlayform(props.displayRules.campaign_platforms);
    if (props.plan === PREMIUM_PLAN) {
      setIsPremium(true);
    }
    if (props.displayRules.file_provider === "LINK") {
      setDownloadLink(props.displayRules.file_url);
    }
    if (props.displayRules.file_provider === "S3") {
      setFilename(
        props.displayRules.file_url.split("/")[
          props.displayRules.file_url.split("/").length - 1
        ]
      );
    }
  }, []);

  const onChangeDownloadType = (value) => {
    props.updateDisplayRules({ data: { file_provider: value } });
  };

  const handleFileChange = (e) => {
    setFilename(e.target.files[0].name);
    const file = e.target.files[0];
    props.updateDisplayRules({ data: { uploaded_file: file } });
  };

  const onChangeFileUrl = (value) => {
    props.updateDisplayRules({ data: { file_url: value } });
  };

  const removeFileUrl = () => {
    setFilename("");
    props.updateDisplayRules({ data: { file_url: "" } });
  }
  const pricingPlanCall = async (type) => {
    try {
      const URL =
        `${process.env.REACT_APP_API_URL}/api/${type}/?shop=${props.shop}`.toString();
      const response = await axios.get(URL);
      if (response && response.status && response.status === 200) {
        window.open(response.data.url, "_top");
      }
    } catch (errors) {
      console.error(errors);
    }
  };
  const onPopupClick = (e) => {
    e.stopPropagation();
    pricingPlanCall("yearly_starter");
};

  return (
    <ChangableCompContainer>
      {showPremiumBar && (
        <PreviewContainer
          justifyContent="initial"
          onClick={() => setShowPremiumBar(false)}
        >
          <PremiumBar onClick={(e) => onPopupClick(e)}>
            <CommonText color="white">
              This template is a premium template. Upgrade to use it.
            </CommonText>
            <IconTextButtonGradient>
              <img src={crownWhite} alt="crown white" /> <div>UPGRADE</div>
            </IconTextButtonGradient>
            <WhiteCross onClick={() => setShowPremiumBar(false)}>
              <img src={crossWhite} alt="cross-icon" width="100%" />
            </WhiteCross>
          </PremiumBar>
        </PreviewContainer>
      )}
      <CommonText>Select how you want to provide your file</CommonText>
      <Maindiv
        colum={"40% 60%"}
        gap={"2%"}
        responsive={"100%"}
        responsiveValue={"670px"}
      >
        <RoundSelectText onClick={() => onChangeDownloadType("LINK")}>
          <RoundSelect selected={props.displayRules.file_provider === "LINK"}>
            <div></div>
          </RoundSelect>
          <CommonText>Download link</CommonText>
        </RoundSelectText>
        <RoundSelectText
          onClick={() => {
            isPremium ? onChangeDownloadType("S3") : setShowPremiumBar(true);
          }}
        >
          <RoundSelect selected={props.displayRules.file_provider === "S3"}>
            <div></div>
          </RoundSelect>
          <TextWithIcon>
            <CommonText>Upload File On Server</CommonText>
            <img
              style={{ width: "20px", height: "20px" }}
              src={crown}
              alt="crown white"
            />
          </TextWithIcon>
        </RoundSelectText>
      </Maindiv>
      {props.displayRules.file_provider === "LINK" ? (
        <InputRow>
          <InputField
            type="text"
            placeholder="Enter file URL"
            defaultValue={downloadLink}
            error={props.saveErrors && props.saveErrors.file_url}
            onChange={(e) => onChangeFileUrl(e.target.value)}
          />
        </InputRow>
      ) : props.displayRules.file_provider === "S3" &&
        props.displayRules.file_url !== "" ? (
        <TextWithIconAndBorder>
          <CommonText>{filename}</CommonText>
          <CrossContainer onClick={() => removeFileUrl()}>
            <img src={cross} alt="cross" />
          </CrossContainer>
        </TextWithIconAndBorder>
      ) : (
        <InputRow>
          <FileLabel
            error={props.saveErrors && props.saveErrors.uploaded_file}
            htmlFor="fileInput"
          >
            {filename !== "" ? filename : "Choose a file"}
            <FileInput
              id="fileInput"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </FileLabel>
        </InputRow>
      )}
      <FormAlert>
        {props.displayRules.file_provider === "LINK"
          ? props.saveErrors.file_url
          : props.saveErrors.uploaded_file}
      </FormAlert>
    </ChangableCompContainer>
  );
};

const mapStateToProps = (state) => ({
  saveErrors: state.template.saveErrors,
  displayRules: state.template.displayRules,
  plan: state.campaign.currentPlan,
  shop: state.campaign.shop,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FileToDownload);
