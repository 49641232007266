import axios from "axios";
import React from "react";
import "react-dates/lib/css/_datepicker.css";
import Navbar from "./components/Navbar";
import "./css/custom.css";
import Routes from "./routes";
//Store
import { store } from "./configureStore";

//Persist
// import { PersistGate } from "redux-persist/integration/react";

import { Provider, ReactReduxContext } from "react-redux";

//add the following lines to any file where you import axios
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

const App = ({shop, plan}) => {
  return (
    <Provider store={store} context={ReactReduxContext}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <div className="app">
        <Navbar shop={shop} plan={plan} />
        <Routes />
      </div>
      {/* </PersistGate>  */}
    </Provider>
  );
};

export default App;
