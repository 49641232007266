import {
  ROUTE_STATE,
  TEMPLATE_TYPE,
  TEMPLATE_TYPE_NUMBER,
  UPDATE_CAMPAIGN_ID,
} from "../constants";
import { BASE_LIST_VIEW ,DASHBOARD } from "../constants/componentName";
import { FREE, SUBSCRIPTION } from "../constants/templateConstants";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleRouteState = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    link: data,
  };
};

export const handleTemplateType = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    template_type: data,
  };
};

export const handleTemplateTypeNumber = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    template_type_number: data,
  };
};

export const handleUpdateCampaignId = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    campaign_id: data,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [ROUTE_STATE]: handleRouteState,
  [TEMPLATE_TYPE]: handleTemplateType,
  [TEMPLATE_TYPE_NUMBER]: handleTemplateTypeNumber,
  [UPDATE_CAMPAIGN_ID]: handleUpdateCampaignId,
};

// default initial state
const initialState = {
  link: DASHBOARD,
  template_type: null,
  template_type_number: null,
  campaign_id: null,
};

export default function routeReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
