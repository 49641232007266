import styled from "styled-components";

export const EditorContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding: 20px;
    margin-left : 15px;

`;

export const EditSectionWrapper = styled.div`
background-color: #f0f4f8;
height: 100vh;

`;



export const EditorStyledInput = styled.input`
  border-radius: 6px;
  width: 500px;
  border: ${(props) =>
    props.error ? `1px solid #fc5768` : `1px solid #BFCFE0`};
  background-color: ${(props) =>
    props.error ? `rgba(252, 87, 104, 0.05)` : `#FCFDFE`};
  color: #1f2939;
  font-size: 13px;
  padding: 5px 10px;
  min-height: 43px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    outline-color: #c2cce1;
  }
`;


export const StyledArea = styled.textarea`
  border-radius: 6px;
  border: ${(props) =>
    props.error ? `1px solid #fc5768` : `1px solid #BFCFE0`};
  background-color: ${(props) =>
    props.error ? `rgba(252, 87, 104, 0.05)` : `#FCFDFE`};
  color: #1f2939;
  font-size: 13px;
  padding: 5px 10px;
  width: 500px;
  height: 140px; /* Increase the min-height */
  margin-bottom: 20px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  :-ms-input-placeholder {
    color: rgba(47, 57, 78, 0.7);
  }
  &:focus {
    outline-color: #c2cce1;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;


export const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const BodyWrapper = styled.div`
    height: auto
`;