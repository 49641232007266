import subscription1 from "../assets/templates/subscribe_1.png";
import subscription3 from "../assets/templates/subscribe_5.png";
import subscription4 from "../assets/templates/subscribe_4.png";
import subscription5 from "../assets/templates/subscribe_3.png";
import subscription2 from "../assets/templates/subscribe_2.png";




import * as constant from "../constants/templateConstants";

const allImages = [
    {
        image: subscription1,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_1,
        isPremium: true,
    },
    {
        image: subscription2,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_2,
        isPremium: true,
    },
    {
        image: subscription3,
        template_type: constant.FREE,
        template_type_number: constant.SUBSCRIPTION_3,
        isPremium: false,
    },
    {
        image: subscription4,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_4,
        isPremium: true,
    },
    {
        image: subscription5,
        template_type: constant.SUBSCRIPTION,
        template_type_number: constant.SUBSCRIPTION_5,
        isPremium: true,
    },
    
]

export default allImages;