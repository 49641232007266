import React from "react";
import { connect } from "react-redux";
import { updateDisplayRules } from "../../actions/actionTemplate";
import { CommonText } from "../../styledComponent/common";
import {
  LabelP,
  Maindiv,
  RoundSelect,
  RoundSelectText
} from "../../styledComponent/step2";
import DownloadType from "./DownloadType";
import ExitIntent from "./ExitIntent";
import FileToDownload from "./FileToDownload";
import Platform from "./Platform";
import ShowPopupAfter from "./ShowPopupAfter";
import UserBehavior from "./UserBehavior";

const Step2 = (props) => {
  const onChangeShowUpOption = (value) => {
    props.updateDisplayRules({ data: { show_up_option: value } });
  };

  const showExitIntentPopup = (value) => {
    props.updateDisplayRules({ data: { exit_intent_popup: value } });
  };

  // useEffect(() => {
  //   console.log("displayRules", props.displayRules);
  // }, [props.displayRules]);

  return (
    <Maindiv colum={"100%"}>
      <Maindiv colum={"100%"}>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"40% 58%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          responsive={"100%"}
          responsiveValue={"670px"}
        >
          <LabelP>Show Up Option</LabelP>
          <Maindiv
            colum={"49% 49%"}
            gap={"2%"}
            responsive={"100%"}
            responsiveValue={"670px"}
          >
            <RoundSelectText
              onClick={() => onChangeShowUpOption("EVERY_VISIT")}
            >
              <RoundSelect
                selected={props.displayRules.show_up_option === "EVERY_VISIT"}
              >
                <div></div>
              </RoundSelect>
              <CommonText>Every Visit</CommonText>
            </RoundSelectText>
            <RoundSelectText onClick={() => onChangeShowUpOption("EVERY_PAGE")}>
              <RoundSelect
                selected={props.displayRules.show_up_option === "EVERY_PAGE"}
              >
                <div></div>
              </RoundSelect>
              <CommonText>Every Page</CommonText>
            </RoundSelectText>
          </Maindiv>
        </Maindiv>
        
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>Show Popup After</LabelP>
          </Maindiv>
          <ShowPopupAfter />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>User Behavior</LabelP>
          </Maindiv>
          <UserBehavior />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>Display On Devices</LabelP>
          </Maindiv>
          <Platform />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>Download Type</LabelP>
          </Maindiv>
          <DownloadType />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>File To Download</LabelP>
          </Maindiv>
          <FileToDownload />
        </Maindiv>
        <Maindiv
          style={{ backgroundColor: `#ffffff` }}
          colum={"100%"}
          gap={"2%"}
          border={"1px solid #C2CCE1"}
          borderRadius={"10px"}
          padding={"15px 30px"}
          margin={"15px 0 0 0"}
        >
          <Maindiv>
            <LabelP>Exit Intent</LabelP>
          </Maindiv>
          <ExitIntent />
        </Maindiv>
      </Maindiv>
    </Maindiv>
  );
};

const mapStateToProps = (state) => ({
  template_type: state.route.template_type,
  template_type_number: state.route.template_type_number,
  campaign_id: state.route.campaign_id,
  displayRules: state.template.displayRules,
  campaignViaId: state.campaign.campaignViaId,
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
