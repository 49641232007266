import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BASE_LIST_VIEW, CAMPAIGN_LIST } from "../constants/componentName";

import changeRoute from "./commonComponents/changeRoute";

import {
  CommonText,
  CommonTitle,
  IconTextButtonGradient,
} from "../styledComponent/common";

import crownWhite from "../assets/icons/crown-white.svg";
import Crown from "../assets/icons/crown.svg";
import Active from "../assets/img/active.png";
import Edit from "../assets/img/edit.png";

import { PREMIUM_PLAN } from "../constants";
import { UpperSection } from "../styledComponent/campaignList";

import axios from "axios";
import {
  ActiveSign,
  EditIcon,
  Integration,
  IntegrationBody,
  IntegrationContainer,
  IntegrationDescription,
  IntegrationImage,
  IntegrationPremiumBar,
  IntegrationPremiumContainer,
  IntegrationTitle,
} from "../styledComponent/integration";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import { defaultApi } from "../utils/axiosApi";
import IntegrationModal from "./IntegrationModal";
import Mailchimp from "../assets/img/mailchimp.webp";
import Omnisend from "../assets/img/Omnisend.webp";
import Klaviyo from "../assets/img/newklaviyo.png";

// Importing Mailchimp and Omnisend images

const Integrations = (props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupName, setPopupName] = useState("");
  const [configuredIntegrations, setConfiguredIntegrations] = useState([]);
  const isPremium = props.plan === PREMIUM_PLAN;
  const [isLoading, setIsLoading] = useState({});

  const [showPremiumBar, setShowPremiumBar] = useState({});
  const [configurationFields, setConfigurationFields] = useState({
    Mailchimp: {
      icon: Mailchimp,
      fields: [
        {
          name: "api_key",
          label: "API Key",
          type: "text",
          error: false,
          value: "",
          validation: {
            required: true,
          },
          placeholder: "Enter Mailchimp API Key",
        },
        {
          name: "username",
          label: "Username",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: true,
          },
          placeholder: "Enter Mailchimp Username",
        },
        {
          name: "list_id",
          label: "Audience ID",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: true,
          },
          placeholder: "Enter Mailchimp Audience ID",
        },
        {
          name: "tagname",
          label: "Tag Name",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: false,
          },
          placeholder: "Enter Mailchimp Tag Name",
        },
      ],
    },
    Omnisend: {
      icon: Omnisend,
      fields: [
        {
          name: "api_key",
          label: "API Key",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: true,
          },
          placeholder: "Enter Omnisend API Key",
        },
        {
          name: "tagname",
          label: "Tag Name",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: true,
          },
          placeholder: "Enter Omnisend Tag Name",
        },
      ],
    },
    Klaviyo: {
      icon: Klaviyo,
      fields: [
        {
          name: "api_key",
          label: "API Key",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: true,
          },
          placeholder: "Enter Klaviyo API Key",
        },
        {
          name: "list_id",
          label: "List ID",
          type: "text",
          value: "",
          error: false,
          validation: {
            required: true,
          },
          placeholder: "Enter Klaviyo List ID",
        },
      ],
    },
  });

  const onPopupClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading({
        fetch: true,
      });
      const url = `integration/`;
      const response = await defaultApi(url, "GET");
      if (response.status === 200) {
        const data = response.data;
        data.forEach((item) => {
          setConfiguredIntegrations((prevState) => [...prevState, item.name]);
          setConfigurationFields((prevState) => ({
            ...prevState,
            [item.name]: {
              icon: configurationFields[item.name].icon,
              fields: configurationFields[item.name].fields.map((field) => ({
                ...field,
                value: item.credentials[field.name],
              })),
            },
          }));
        });
      }
      setIsLoading({
        fetch: false,
      });
    };
    fetchData();
  }, []);


  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onLeave = () => {
    popupToggle();
    props.updateCampaignId({ data: null });
    props.UpdateToInitialState();
    setTimeout(() => {
      changeRoute(
        props.campaign_id ? CAMPAIGN_LIST : BASE_LIST_VIEW,
        props.handleUpdateRouteState
      );
    }, 100);
  };

  const useUnload = (fn) => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
      cb.current = fn;
    }, [fn]);

    useEffect(() => {
      const onUnload = (...args) => cb.current?.(...args);
      window.addEventListener("beforeunload", onUnload);

      return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
  };

  useUnload((e) => {
    e.preventDefault();
    if (!props.isFormChanged) {
      return undefined;
    } else {
      e.returnValue = "";
    }
  });

  const handleIntegrationClick = (name) => {
    setPopupName(name);
    popupToggle();
  };

  const fieldsValidation = () => {
    return !configurationFields[popupName].fields.some((item) => {
      const isError = item.validation.required && item.value === "";
      setConfigurationFields((prevState) => ({
        ...prevState,
        [popupName]: {
          ...prevState[popupName],
          fields: prevState[popupName].fields.map((field) =>
            field.name === item.name ? { ...field, error: isError } : field
          ),
        },
      }));
      return isError;
    });
  };
  const handleIntegrationUpdate = async () => {
    setIsLoading({
      update: true,
    });
    if (fieldsValidation()) {
      const payload = {
        name: popupName,
        credentials: configurationFields[popupName].fields.reduce(
          (acc, item) => ({ ...acc, [item.name]: item.value }),
          {}
        ),
      };

      const url = `integration/`;

      try {
        const response = await defaultApi(url, "POST", payload);

        if (response.status === 201) {
          const data = response.data;
          setConfiguredIntegrations((prevState) => [...prevState, data.name]);
          setConfigurationFields((prevState) => ({
            ...prevState,
            [data.name]: {
              icon: configurationFields[data.name].icon,
              fields: configurationFields[data.name].fields.map((field) => ({
                ...field,
                value: data.credentials[field.name],
              })),
            },
          }));
          popupToggle();
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          console.log("Invalid data");
          setConfigurationFields((prevState) => ({
            ...prevState,
            [popupName]: {
              icon: configurationFields[popupName].icon,
              fields: configurationFields[popupName].fields.map((field) => ({
                ...field,
                error: true,
              })),
            },
          }));
        }
      }
    } else {
      console.log("Integration not updated");
    }
    setIsLoading({
      update: false,
    });
  };
  const handleInputChange = (fieldName, newValue) => {
    setConfigurationFields((prevState) => ({
      ...prevState,
      [popupName]: {
        ...prevState[popupName],
        fields: prevState[popupName].fields.map((field) =>
          field.name === fieldName ? { ...field, value: newValue } : field
        ),
      },
    }));
  };

  const handleDeleteIntegration = async () => {
    setIsLoading({
      delete: true,
    });
    const url = `integration/${popupName}/`;
    const response = await defaultApi(url, "DELETE");
    if (response.status === 204) {
      setConfiguredIntegrations((prevState) =>
        prevState.filter((item) => item !== popupName)
      );
      setConfigurationFields((prevState) => ({
        ...prevState,
        [popupName]: {
          fields: configurationFields[popupName].fields.map((field) => ({
            ...field,
            value: "",
          })),
        },
      }));
      setIsLoading({
        delete: false,
      });
      popupToggle();
    }
  };

  const pricingPlanCall = async () => {
    try {
      const URL =
        `${process.env.REACT_APP_API_URL}/api/yearly_starter/?shop=${props.shop}`.toString();
      setIsLoading({
        fetch: true,
      });
      const response = await axios.get(URL);
      setIsLoading({});
      if (response && response.status && response.status === 200) {
        window.open(response.data.url, "_top");
      }
    } catch (errors) {
      console.error(errors);
    }
  };


  return (
    <IntegrationBody>
      {isLoading.fetch ? (
        <ComponentCircleLoader />
      ) : (
        <div>
          {popupOpen && (
            <IntegrationModal
              text={"Configure " + popupName}
              configureFields={configurationFields[popupName]}
              handleOnChange={handleInputChange}
              buttonText={
                configuredIntegrations.includes(popupName) ? "Update" : "Save"
              }
              toggle={popupToggle}
              onAccept={handleIntegrationUpdate}
              deleteIntegration={handleDeleteIntegration}
              isLoading={isLoading}
            />
          )}

          <UpperSection
            responsive={"100%"}
            responsiveValue={true}
            style={{ minHeight: `102px` }}
          >
            <CommonTitle>Integrations</CommonTitle>
          </UpperSection>

          <IntegrationContainer>
            {Object.keys(configurationFields).map((integration) => (
              <Integration
                onClick={() =>
                console.log("integration", integration)
                }
                key={integration}
              >
                {showPremiumBar[integration] && (
                  <IntegrationPremiumContainer
                    justifyContent="initial"
                    onClick={() => setShowPremiumBar(false)}
                  >
                    <IntegrationPremiumBar onClick={(e) => onPopupClick(e)}>
                      <CommonText color="white">
                        Integration is a premium feature. Upgrade to use it.
                      </CommonText>
                      <IconTextButtonGradient onClick={() => pricingPlanCall()}>
                        <img src={crownWhite} alt="crown white" />{" "}
                        <div>UPGRADE</div>
                      </IconTextButtonGradient>
                    </IntegrationPremiumBar>
                  </IntegrationPremiumContainer>
                )}
                {configuredIntegrations.includes(integration) && (
                  <ActiveSign src={Active} alt="active" />
                )}
                <EditIcon
                  onClick={() =>
                    isPremium
                      ? handleIntegrationClick(integration)
                      : setShowPremiumBar({
                          [integration]: true,
                        })
                  }
                  src={isPremium ? Edit : Crown}
                  alt="edit"
                />
                <IntegrationImage src={configurationFields[integration].icon} />
                <IntegrationTitle>{integration}</IntegrationTitle>
                <IntegrationDescription>
                  Integrate your {integration} account with your campaign
                </IntegrationDescription>
              </Integration>
            ))}
          </IntegrationContainer>
        </div>
      )}
    </IntegrationBody>
  );
};

const mapStateToProps = (state) => ({
  plan: state.campaign.currentPlan,
  shop: state.campaign.shop,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
