import {
  CAMPAIGN_STATE_TO_INITIAL,
  CD_DATE_FILTER_DATA,
  CREATE_CAMPAIGN,
  DASHBOARD_FILTER,
  DATE_FILTER_DATA,
  DELETE_CAMPAIGN,
  DELETE_LEAD,
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_VIA_ID,
  GET_LEADS_LIST,
  SET_CURRENT_PLAN,
  UPDATE_CAMPAIGN,
  UPDATE_SHOP,
} from "../constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getCampaignList(value) {
  return {
    type: GET_CAMPAIGN_LIST,
    payload: value,
  };
}

export function getLeadsList(value) {
  return {
    type: GET_LEADS_LIST,
    payload: value,
  };
}

export function createCampaign(value) {
  return {
    type: CREATE_CAMPAIGN,
    payload: value,
  };
}

export function getCampaignViaId(value) {
    return {
      type: GET_CAMPAIGN_VIA_ID,
      payload: value,
    };
  }

  export function updateCampaign(value) {
    return {
      type: UPDATE_CAMPAIGN,
      payload: value,
    };
  }

  export function deleteCampaign(value) {
    return {
      type: DELETE_CAMPAIGN,
      payload: value,
    };
  }

  export function deleteLead(value) {
    return {
      type: DELETE_LEAD,
      payload: value,
    };
  }

  export const setCampaignStateToInitial = () => {
    return {
      type: CAMPAIGN_STATE_TO_INITIAL,
    };
  };

  export function setCurrentPlan (value) {
    return {
      type: SET_CURRENT_PLAN,
      payload: value,
    };
  }

  export function setShop(value) {
    return {
      type: UPDATE_SHOP,
      payload: value,
    };
  }

  export function getDashboardFilter () {
    
    return {
      type: DASHBOARD_FILTER,
    };
  }

  export function getDateFilterData (value) {
    //
    return {
      type: DATE_FILTER_DATA,
      payload: value,
    };
  }
  export function getCDDateFilterData (value) {
    //
    return {
      type: CD_DATE_FILTER_DATA,
      payload: value,
    };
  }