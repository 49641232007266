import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BASE_LIST_VIEW, CAMPAIGN_LIST } from "../constants/componentName";
import { PROMO_CODES } from "../constants/templateConstants";
import deepCopy from "../utils/deepCopy";
import allTemplates from "./allTemplates";

import WarningModal from "./WarningModal";
import changeRoute from "./commonComponents/changeRoute";

import {
  CommonColorButton,
  CommonText,
  CommonTitle,
  CommonWhButton,
  FormAlert,
  NoDataContainer,
} from "../styledComponent/common";

import { getCampaignList } from "../actions/actionCampaign";
import noDataArtWork from "../assets/img/artwork.svg";
import { UpperSection } from "../styledComponent/campaignList";
import {
  BodyWrapper,
  ButtonWrapper,
  EditSectionWrapper,
  EditorContainer,
  EditorStyledInput,
  StyledArea,
} from "../styledComponent/editemail";
import { LabelP, Maindiv } from "../styledComponent/step2";
import ComponentCircleLoader from "../utils/Loader/ComponentCircleLoader";
import { defaultApi } from "../utils/axiosApi";

const EditEmail = (props) => {
  const [emailTemplate, setEmailTemplate] = useState({
    email_subject: "",
    email_body: "",
    campaign_id: "",
  });
  const [templateData, setTemplateData] = useState([]);
  const [isLoading, setIsLoading] = useState({});

  const [popupOpen, setPopupOpen] = useState(false);
  let allTemplatesCopy = deepCopy(allTemplates);
  const [emailErrors, setEmailErrors] = useState({});

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const onLeave = () => {
    popupToggle();
    props.updateCampaignId({ data: null });
    props.UpdateToInitialState();
    setTimeout(() => {
      changeRoute(
        props.campaign_id ? CAMPAIGN_LIST : BASE_LIST_VIEW,
        props.handleUpdateRouteState
      );
    }, 100);
  };

  useEffect(() => {
    if (props.campaign_id && props.template_type) {
      const payload = {
        template_type: props.template_type,
        campaign_id: props.campaign_id,
      };
      props.getCampaignViaId(payload);
    } else if (props.template_type_number) {
      props.updateField({
        data: { ...allTemplatesCopy[props.template_type_number].form },
      });
      props.updateTemplate({
        data: {
          ...allTemplatesCopy[props.template_type_number].templateObject,
        },
      });
    }
    if (props.template_type && props.template_type === PROMO_CODES) {
      props.getDiscountCoupons();
    }
  }, []);

  const useUnload = (fn) => {
    const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
      cb.current = fn;
    }, [fn]);

    useEffect(() => {
      const onUnload = (...args) => cb.current?.(...args);
      window.addEventListener("beforeunload", onUnload);

      return () => window.removeEventListener("beforeunload", onUnload);
    }, []);
  };

  useUnload((e) => {
    e.preventDefault();
    if (!props.isFormChanged) {
      return undefined;
    } else {
      e.returnValue = "";
    }
  });

  const validatePayload = (payload) => {
    if (!payload.email_subject) {
      setEmailErrors({
        email_subject: "Email subject is required",
      });
      return false;
    }
    if (!payload.email_body) {
      setEmailErrors({
        email_body: "Email body is required",
      });
      return false;
    }
    if (!payload.campaign_id) {
      setEmailErrors({
        campaign_id: "This campaign does not provide email sending feature",
      });
      return false;
    }
    setEmailErrors({});
    return true;
  };

  const saveEmailTemplate = async () => {
    setIsLoading({
      save: true,
    });
    const url = `email-settings/`;
    const payload = {
      email_subject: emailTemplate.email_subject,
      email_body: emailTemplate.email_body,
      campaign_id: emailTemplate.campaign_id,
    };
    if (validatePayload(payload)) {
      try {
        const response = await defaultApi(url, "POST", payload);
        if (response.status === 201) {
          setIsLoading({
            save: false,
            loaded: true,
          });
        }
      } catch (error) {
        console.error(
          "An error occurred while saving the email template:",
          error
        );
        setIsLoading({});
      }
    }
    else{
      setIsLoading({});
    }
  };

  useEffect(() => {
    async function fetchTemplates() {
      setIsLoading({
        fetch: true,
      });
      const url = `email-settings/`;
      try {
        const response = await defaultApi(url, "GET");
        const data = response.data;
        if (data.length === 0) {
          setTemplateData([]);
          setEmailTemplate({
            email_subject: "",
            email_body: "",
            campaign_id: "",
          });
          return;
        }
        setTemplateData(data);
        if(props.campaignList?.results?.length === 0){
          return;
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching the email templates:",
          error
        );
      } finally {
        setIsLoading({});
      }
    }
    fetchTemplates();
  }, []);

  useEffect(() => {
    props.getCampaignList();
  }, []);

  // populate the data of emailtemplate on selectedList change



  // const initiateDeletion = async () => {
  //   console.log("clickCount", emailTemplate);
  //   setClickCount(clickCount + 1);
  //   if (clickCount === 1) {
  //     setIsLoading({
  //       delete: true,
  //     });
  //     console.log("Delete");
  //     setClickCount(0);

  //     // Delete API call
  //     const url = `email-settings/` + emailTemplate.campaign_id + "/";
  //     try {
  //       const response = await defaultApi(url, "DELETE");
  //       if (response.status === 204) {
  //         setTemplateData([]);
  //         setEmailTemplate({
  //           email_subject: "",
  //           email_body: "",
  //           campaign_id: "",
  //         });
  //       }
  //     } catch (error) {
  //       console.error(
  //         "An error occurred while deleting the email template:",
  //         error
  //       );
  //     }
  //     setIsLoading({});
  //   }

  //   setTimeout(() => {
  //     setClickCount(0);
  //   }, 4000);
  // };

  console.log("loading checker", isLoading);


const [selectedList, setSelectedList] = useState({})
const [isOpenListSelect, setIsOpenListSelect] = useState(false)

useEffect(() => {
  const foundTemplate = templateData.find(template => template.campaign_id === selectedList.id)
  if(foundTemplate){
  setEmailTemplate({
        email_subject: foundTemplate?.email_subject,
        email_body: foundTemplate?.email_body,
        campaign_id: foundTemplate?.campaign_id,
  });
} else{
  setEmailTemplate({
            email_subject: "",
            email_body: "",
            campaign_id: "",
          });
}

}, [selectedList, templateData]);

  return (
    <EditSectionWrapper>
      {isLoading?.fetch ? (
        <ComponentCircleLoader />
      ) : props.campaignList?.results?.length !== 0 ? (
        <BodyWrapper>
          {popupOpen && (
            <WarningModal
              text="Are you sure you want to leave this page?"
              description={`Hey there! It looks like you are in the middle of your email customisation and
               you haven’t saved your content. Save before you go!`}
              buttonText="Leave"
              toggle={popupToggle}
              onAccept={onLeave}
            />
          )}

          <UpperSection
            responsive={"100%"}
            responsiveValue={true}
            style={{ minHeight: `102px` }}
          >
            <CommonTitle>Customize Email Template</CommonTitle>
            <ButtonWrapper>
              {/* {templateData.length > 0 && (
                <CommonColorButton
                  bgColor="#fc5768"
                  onClick={initiateDeletion}
                  disabled={isLoading.delete}
                >
                  {isLoading.delete
                    ? "Deleting"
                    : clickCount === 1
                    ? "Confirm"
                    : "Delete"}
                </CommonColorButton>
              )} */}
              {selectedList?.campaign_name && 
              <CommonWhButton
                borderColor="#3A6B60"
                color="#3A6B60"
                onClick={() => !isLoading.save && saveEmailTemplate()}
              >
                {isLoading.save ? "Saving" : "Save"}
                
              </CommonWhButton>
              }
            </ButtonWrapper>
          </UpperSection>

          <EditorContainer>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  id="font-picker"
                  onClick={() => setIsOpenListSelect(!isOpenListSelect)}
                  className={isOpenListSelect ? "expanded" : ""}
                  style={{
                    width: "500px",
                    boxShadow: "none",
                    border: "1px solid rgb(194, 206, 225)",
                    borderRadius: "6px",
                    backgroundColor: "rgb(252, 253, 254)",
                    fontSize: "13px",
                    padding: "2px 2px",
                  }}
                >
                  <div
                    type="button"
                    className="dropdown-button"
                    style={{
                      backgroundColor: "rgb(252, 253, 254)",
                      height: "30px !important",
                    }}
                  >
                    <p
                      className="dropdown-font-family"
                      style={{
                        color: "rgb(47, 57, 78)",
                        textTransform: "capitalize",
                      }}
                    >
                      {selectedList?.campaign_name
                        ? selectedList?.campaign_name
                        : "Select a campaign"}
                    </p>
                    <p className="dropdown-icon finished"></p>
                  </div>
                  <ul
                    className="campaign-list"
                    style={{
                      border: isOpenListSelect
                        ? "1px solid rgb(194, 206, 225)"
                        : "none",
                      backgroundColor: "rgb(252, 253, 254)",
                      fontSize: "13px",
                      borderRadius: "6px",
                      boxShadow: "rgb(240, 244, 248) 0px 3px 6px",
                      height: isOpenListSelect ? "130px" : "0px",
                      animation: isOpenListSelect
                        ? "fadeIn 0.2s"
                        : "fadeOut 0.2s",
                    }}
                  >
                    {props.campaignList?.results?.map((campaign) => (
                      <li className="campaign-list-item" key={campaign.id}>
                        <button
                          type="button"
                          id="font-button-arimo"
                          onClick={() => {
                            setSelectedList(campaign);
                            setEmailTemplate({
                              ...emailTemplate,
                              campaign_id: campaign.id,
                            });
                            setEmailErrors({});
                            setIsLoading({});
                            setIsOpenListSelect(false);
                          }}
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {campaign.campaign_name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <FormAlert>{emailErrors.campaign_id}</FormAlert>
              <FormAlert color="#3A6B60"> {isLoading.loaded && "Email Template saved successfully!"}</FormAlert>
            </div>

            <Maindiv
              style={{
                backgroundColor: `#ffffff`,
                display: !selectedList?.campaign_name && `none`,
              }}
              colum={"100%"}
              gap={"4%"}
              border={"1px solid #C2CCE1"}
              borderRadius={"10px"}
              padding={"15px 30px"}
              margin={"15px 0 0 0"}
              maxH={"700px"}
            >
              <Maindiv>
                <LabelP>Write your Email here</LabelP>
              </Maindiv>
              <EditorStyledInput
              
                error={emailErrors.email_subject}
                type="text"
                value={emailTemplate.email_subject}
                placeholder="Email Subject"
                onChange={(e) =>
                  setEmailTemplate({
                    ...emailTemplate,
                    email_subject: e.target.value,
                  })
                }
              />

              <StyledArea
                // disabled
                error={emailErrors.email_body}
                placeholder="Email Description"
                value={emailTemplate.email_body}
                onChange={(e) =>
                  setEmailTemplate({
                    ...emailTemplate,
                    email_body: e.target.value,
                  })
                }
              />
            </Maindiv>
            {selectedList?.campaign_name && (
              <>
                <CommonText id="link_edit_email">
                  <code id="download_link">{"{$download_link}"}</code> Link to
                  uploaded file
                </CommonText>
                <CommonText id="name_store_edit_email">
                  <code id="shop_name">{"{$shop_name}"}</code> Your Shopify
                  store name
                </CommonText>
              </>
            )}
          </EditorContainer>
        </BodyWrapper>
      ) : (
        <NoDataContainer>
          <img
            src={noDataArtWork}
            alt="no campaigns"
            width="100%"
            style={{ maxWidth: `300px` }}
          />
          <CommonText style={{ textAlign: `center` }}>
            You do not have any campaign created, create one and come back
          </CommonText>
          {/* <CommonColorButton onClick={() => onChangeToTemplates()}>
                  Start Campaign
                </CommonColorButton> */}
        </NoDataContainer>
      )}
    </EditSectionWrapper>
  );
};

const mapStateToProps = (state) => ({
  campaignList: state.campaign.campaignList,
});

const mapDispatchToProps = (dispatch) => ({
  getCampaignList: (params) => dispatch(getCampaignList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEmail);
