import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  updateDisplayRules,
  updateSaveErrors,
} from "../../actions/actionTemplate";
import crown from "../../assets/icons/crown.svg";
import tick from "../../assets/icons/tick_2.svg";
import { PREMIUM_PLAN } from "../../constants";
import {
  CommonText,
  TextWithIcon
} from "../../styledComponent/common";
import {
  ChangableCompContainer,
  CheckBox,
  RoundSelectText
} from "../../styledComponent/step2";

const ExitIntent = (props) => {

  const [isPremium, setIsPremium] = useState(false);

  useEffect(() => {
    if (props.plan === PREMIUM_PLAN) {
      setIsPremium(true);
    }
  }, []);

  const onChangePopupWhenScroll = () => {
    props.updateDisplayRules({
      data: {
        exit_intent_popup: props.displayRules.exit_intent_popup === "ENABLE" ? "DISABLE" : "ENABLE",
      },
    });
  };


  return (
    <ChangableCompContainer>
      <RoundSelectText
        margin={"20px 0 0 0"}
        onClick={() => {
            isPremium && onChangePopupWhenScroll();
        }}
      >
        <CheckBox selected={props.displayRules.exit_intent_popup === "ENABLE"}>
          <img src={tick} alt="tick" />
        </CheckBox>
        <TextWithIcon>
          <CommonText>Show popup when user intent to exit the page</CommonText>
          <img
            style={{ width: "20px", height: "20px" }}
            src={crown}
            alt="crown white"
          />
        </TextWithIcon>
        
      </RoundSelectText>
    </ChangableCompContainer>
  );
};

const mapStateToProps = (state) => ({
  saveErrors: state.template.saveErrors,
  displayRules: state.template.displayRules,
  plan: state.campaign.currentPlan
});

const mapDispatchToProps = (dispatch) => ({
  updateDisplayRules: (params) => dispatch(updateDisplayRules(params)),
  updateSaveErrors: (params) => dispatch(updateSaveErrors(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExitIntent);
