import styled from "styled-components";

export const BaseDetailContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: start;
  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }
`;
export const TabCreateButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
`;
export const TabContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  grid-column-gap: 30px;
  border-bottom: 1px solid #f0f4f8;
  padding: 0 30px 15px 30px;
  margin: 0 -30px;
  background-color: white;
`;
export const TabButtonSection = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 10px;
  grid-gap: 12px;
  align-items: center;
  cursor: pointer;
`;
export const TabIconBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  border: ${(props) =>
    props.isStep1 ? `1px solid #3A6B60` : `1px solid #BFCFE0`};
`;
// export const TabButton = styled.div`
//   font-size: 13px;
//   padding: 7px 15px;
//   border-radius: 4px;
//   color: ${(props) =>
//     props.isStep1 ? `white` : `#2f394e`};
//   background-color: ${(props) =>
//     props.isStep1 ? `#3A6B60` : `white`};
//   cursor: pointer;
//   white-space: nowrap;
//   border: ${(props) =>
//     props.isStep1 ? `1px solid #3A6B60` : `1px solid white`};
//   &:hover {
//     border: 1px solid #3A6B60;
//   }
// `;

export const FormTemplateContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }
`;
export const LeftContainer = styled.div`
  display: grid;
  grid-gap: 25px;
  padding: 30px;
  align-content: start;
`;
export const RightContainer = styled.div`
  position: sticky;
  top: 30px;
  display: grid;
  grid-template-columns: minmax(auto, 825px);
  justify-content: center;
  grid-gap: 25px;
  padding: 30px 15px;
`;
export const TemplateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  justify-content: center;
  background-color: #d7d7d7;
  border-radius: 10px;
  padding: 10px 0 40px 0;
  min-height: 500px;
  align-content: space-between;
`;
export const DomainContainer = styled.div`
  padding: 15px 30px;
  border-bottom: 1px solid #f0f4f8;
`;
export const TemplateSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px 0 40px 0;
`;
export const ResponsiveDiv = styled.div`
  background-color: "red";
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
